
import Vue from 'vue'
import HighlightedCode from '@/components/HighlightedCode.vue'
import { mapState } from 'vuex'
import DialectPicker from '@/components/DialectPicker.vue'
import _ from 'lodash'
export default Vue.extend({
  metaInfo() {
    return {
      title: "SQL Query Formatter for MySQL, PostgreSQL, SQLite, and SQL Server",
      meta: [
        {
          name: "description",
          content: "Paste unformatted SQL, get formatted SQL back. Format SQL for Postgres, MySQL, SQLite, and more."
        }
      ]
    }
  },
  components: { HighlightedCode, DialectPicker },
  data() {
    return {
      input: "",
      output: null
    }
  },
  methods: {
    clear() {
      this.input = null
    }
  },
  computed: {
    ...mapState(['dialect'])
  },
  watch: {
    input: _.debounce(function() {
      this.output = this.input;
    }, 300)
  }


})
