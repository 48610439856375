
  import Vue from 'vue'
  export default Vue.extend({
    props: ['cell', 'params'],
    data() {
      return {
        checked: false,
        rendered: false,
      }
    },
    methods: {
      keydown(_e: KeyboardEvent) {
        if (_e.key === 'Escape') {
          this.$emit('cancel')
        }
      },
      submit() {
        this.$emit('value', this.checked)
      }
    },
    watch: {
      checked() {
      },
      rendered() {
        if (this.rendered) {
          this.checked = !!this.cell.getValue()
          this.$refs.input.focus()
        }
      }
    }
  })
