
import Vue from 'vue'
import { format } from 'sql-formatter'
import { FormatterDialect } from '@shared/lib/dialects/models'
export default Vue.extend({
  props: ['code', 'dialect', 'allowCopy', 'format'],
  data() {
    return {
      copyMessage: "Copy",
      copyIcon: "content_copy",
      copyClass: 'btn-flat',
      copyTitle: null
    }
  },
  computed: {
    formattedCode() {

      if (!this.code) return null

      if (this.format) {
        return format(this.code, { language: FormatterDialect(this.dialect) })
      } else {
        return this.code
      }
    },
    highlightDialect() {
      switch (this.dialect) {
        case 'postgresql':
        case 'redshift':
          return 'pgsql'
        default:
          return 'sql'
      }
    },
  },
  methods: {
    onCopySuccess() {
      this.copyMessage = "Copied"
      this.copyIcon = "done"
      this.copyClass = "btn-success"
      setTimeout(() => {
        this.copyMessage = "Copy"
        this.copyIcon = "content_copy"
        this.copyClass = "btn-flat"
      }, 2000)
    },
    onCopyError(e) {
      this.copyMessage = "Error"
      this.copyTitle = e.message
      this.copyIcon = "error"
      this.copyClass = "btn-error"
      setTimeout(() => {
        this.copyMessage = "Copy"
        this.copyIcon = "content_copy"
        this.copyClass = "btn-flat"
      }, 5000);
    }
  }

})
