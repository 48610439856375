import { render, staticRenderFns } from "./HighlightedCode.vue?vue&type=template&id=6f6ab286&scoped=true&"
import script from "./HighlightedCode.vue?vue&type=script&lang=ts&"
export * from "./HighlightedCode.vue?vue&type=script&lang=ts&"
import style0 from "./HighlightedCode.vue?vue&type=style&index=0&id=6f6ab286&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6ab286",
  null
  
)

export default component.exports