
import Vue from 'vue'
import templates from '@/lib/templates'
import { mapState } from 'vuex'
import { Template } from '@shared/lib/dialects/template'

interface Data {
  templates: Template[]
}

export default Vue.extend({
  data(): Data {
    return {
      templates
    }
  },
  computed: {
    ...mapState(['dialect']),
  }
})
