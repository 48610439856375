
import { SqliteExamples } from '@/data/examples'
import { Dialect, DialectTitles } from '@shared/lib/dialects/models'
import Vue from 'vue'
import { mapState } from 'vuex'
interface State {
  dialect: Dialect
}
export default Vue.extend({
  metaInfo() {
    return {
      title: `${this.dialectName} Syntax Examples`,
      meta: [
        {
          name: "description",
          content: `Simple code examples for ${this.dialectName}, ALTER TABLE, ALTER COLUMN, etc.`
        }
      ]
    }
  },
  beforeRouteEnter(to, _from, next) {
    next((component: any) => {
      component.dialect = to.params.dialect_id
    })
  },
  data(): State {
    return {
      dialect: 'sqlite'
    }
  },
  computed: {
    ...mapState(['examples']),
    dialectName() {
      return DialectTitles[this.dialect] || 'Sqlite'
    },
    dialectExamples() {
      return this.examples[this.dialect] || SqliteExamples
      // return []
    }
  }
})
