
import { mapState } from 'vuex'
import templates from '@/lib/templates'
import Vue from 'vue'
import SchemaBuilder from '@shared/components/SchemaBuilder.vue'
import DialectPicker from '@/components/DialectPicker.vue'
import { format } from 'sql-formatter'
import { SqlGenerator } from '@shared/lib/sql/SqlGenerator';
import HighlightedCode from '@/components/HighlightedCode.vue'
import { FormatterDialect } from '@shared/lib/dialects/models'

export default Vue.extend({
  metaInfo() {
    return {
      title: `${this.template.id} table CREATE_TABLE example`,
      meta: [
        {
          name: 'description',
          content: `CREATE TABLE syntax for a ${this.template.id} SQL table for Postgres, SqlServer, MySQL, Sqlite, or Redshift.`
        }
      ]
    }
  },
  components: {
    SchemaBuilder,
    DialectPicker,
    HighlightedCode
  },
  beforeRouteEnter(to, _from, next) {
    next((component: any) => {
      return component.setTemplate(to.params.id)
    })
  },
  beforeRouteUpdate(to, _from, next) {
    next(this.setTemplate(to.params.id))
  },
  data() {
    return {
      generator: null,
      template: null,
      sql: null
    }
  },
  watch: {
    dialect() {
      this.generator.dialect = this.dialect
      this.genSql()
    }
  },
  computed :{
    ...mapState(['dialect'])
  },
  methods: {
    genSql() {
      const unformatted = this.generator.buildSql(this.template.toSchema(this.dialect))
      this.sql = format(unformatted, { language: FormatterDialect(this.dialect)})
    },
    setTemplate(id: string) {
      this.generator = new SqlGenerator(this.dialect)
      const t = templates.find((t) => t.id === id)
      if (t) {
        this.template = t
        this.genSql()
        return true
      }
      return false
    }
  },
  mounted() {
  }
})
