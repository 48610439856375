
import Vue, { PropType } from 'vue'
import { TabulatorFull, Tabulator } from 'tabulator-tables'
import { getDialectData } from '../lib/dialects'
import tab from '../lib/tabulator'
import {vueEditor, vueFormatter} from '../lib/tabulator/helpers'
import NullableInputEditor from './tabulator/NullableInputEditor.vue'
import CheckboxEditor from './tabulator/CheckboxEditor.vue'
import CheckboxFormatter from './tabulator/CheckboxFormatter.vue'
import { Dialect, SchemaItem } from '../lib/dialects/models'

interface SchemaBuilderData {
  builtColumns: SchemaItem[],
  tabulator: Tabulator
  defaultName: string
  columnsModified: boolean
}

export default Vue.extend({
  props: {
    initialColumns: Array as PropType<SchemaItem[]>,
    dialect: String as PropType<Dialect>,
    resetOnUpdate: Boolean as PropType<boolean>,
    disabled: Boolean as PropType<boolean>,
    initialEmit: Boolean as PropType<boolean>,
    tableHeight: {
      type: String,
      default: 'auto'
    }
  },
  data(): SchemaBuilderData {
    return {
      builtColumns: [],
      tabulator: null,
      defaultName: 'untitled_table',
      columnsModified: false
    }
  },
  watch: {
    async initialColumns() {
      if (this.resetOnUpdate && this.initialColumns && this.tabulator) {
        await this.tabulator.replaceData([...this.initialColumns])
        this.getData(!!this.initialEmit)
      }
    },
    dialect() {
      this.generator.dialect = this.dialect
      this.schemaChanges = 0
      this.tabulator.replaceData(this.builtColumns)
    },
    builtColumns: {
      deep: true,
      handler() {
        if (this.builtColumns && this.modified) {
          this.$emit('columnsChanged', this.builtColumns)
        }
      }
    }
  },
  computed: {
    editable(){
      return !this.disabled
    },
    modified(){
      return this.columnsModified
    },
    autoCompleteOptions() {
      return {
        freetext: true,
        allowEmpty: false,
        values: getDialectData(this.dialect).columnTypes.map((d) => d.pretty),
        defaultValue: 'varchar(255)',
        showListOnEmpty: true
      }
    },
    disabledFeatures() {
      return getDialectData(this.dialect).disabledFeatures
    },
    tableColumns() {
      const trashButton = () => '<i class="material-icons" title="remove">clear</i>'
      const editable = this.editable
      const dataColumns = [
        {
          title: 'Name',
          field: 'columnName',
          editor: vueEditor(NullableInputEditor),
          formatter: this.cellFormatter,
          tooltip: true,
          editorParams: {
          }
        },
        {title: 'Type', field: 'dataType', editor: 'autocomplete', editorParams: this.autoCompleteOptions,  minWidth: 56,widthShrink:1},

        {
          title: 'Nullable',
          field: 'nullable',
          cssClass: "no-padding no-edit-highlight",
          headerTooltip: "Allow this column to contain a null value",
          editor: vueEditor(CheckboxEditor),
          formatter: vueFormatter(CheckboxFormatter),
          formatterParams: {
            editable
          },
          width: 76,
          widthShrink:1
        },
        {
          title: 'Default Value',
          field: 'defaultValue',
          editor: vueEditor(NullableInputEditor),
          editorParams: {
            allowEmpty: false
          },
          tooltip: true,
          headerTooltip: "The default value of this field. Be sure to add quotes around literal values - eg 'my value'",
          formatter: this.cellFormatter,
          widthShrink:1
        },
        // TODO (Fix this)
        // right now we don't support mysql's EXTRA field. But creating an auto_increment
        // column is still possible. using the autoincrement column type.
        // (
        //   this.disabledFeatures?.informationSchema?.extra ? null : {
        //     title: "Extra",
        //     field: 'extra',
        //     editor: vueEditor(NullableInputEditor),
        //     tooltip: true,
        //     headerTooltip: "EG AUTO_INCREMENT",
        //     formatter: this.cellFormatter,
        //     widthShrink: 1,
        //   }
        // ),
        {
          title: 'Comment',
          field: 'comment',
          formatter: this.cellFormatter,
          editor: vueEditor(NullableInputEditor),
          widthShrink:1,
          tooltip: true,
          headerTooltip: "Leave a friendly comment for other database users about this column"
        },
        {
          title: 'Primary', field: 'primaryKey',
          editor: vueEditor(CheckboxEditor),
          formatter: vueFormatter(CheckboxFormatter),
          formatterParams: {
            editable
          },
          width: 76,
          widthShrink:1,
          cssClass: "no-padding no-edit-highlight"
        },
      ].filter((c) => !!c)
      return editable ? [
        {rowHandle:true, formatter:"handle", width:30, frozen: true, minWidth:30, resizable: false, cssClass: "no-edit-highlight"},
        ...dataColumns,
        {
          formatter: trashButton, width: 36, minWidth: 36, hozAlign: 'center', cellClick: editable ? this.removeRow : undefined, resizable: false, cssClass: "remove-btn no-edit-highlight",
        }
      ] : dataColumns.map((c) => ({...c, editable}))
    },
  },

  methods: {
    getData(markModified: boolean = true) {
      const data = this.tabulator.getData()
      this.builtColumns = data
      this.columnsModified = markModified
    },
    removeRow(_e, cell: Tabulator.CellComponent) {
      this.tabulator.deleteRow(cell.getRow())
    },
    async addRow() {

      const num = this.tabulator.getData().length + 1
      const columnName = `column_${num}`

      const row: Tabulator.RowComponent = await this.tabulator.addRow({ columnName, dataType: 'varchar(255)', nullable: true})
      const nameCell = row.getCell('columnName')
      if (nameCell){
        // don't know why we need this, but we do.
        setTimeout(() => nameCell.edit(), 50)
      }
    },
    cellEdited() {
    },
    cellFormatter: tab.cellFormatter,
    yesNoFormatter: tab.yesNoFormatter
  },
  beforeDestroy() {
    if (this.tabulator) {
      this.tabulator.destroy()
    }
  },
  mounted() {
    const initial = [...this.initialColumns]
    // @ts-ignore-error
    this.tabulator = new TabulatorFull(this.$refs.tabulator, {
      data: initial,
      columns: this.tableColumns,
      movableRows: this.editable,
      columnDefaults: {
        title: '',
        resizable: false,
        minWidth: 56,
        headerSort: false
      },
      layout: 'fitColumns',
      height: this.tableHeight
    })
    // this.getData(!!this.initialEmit)
    this.tabulator.on('tableBuilt', () => this.getData(!!this.initialEmit))
    this.tabulator.on('dataChanged', () => this.getData())
    this.tabulator.on('rowMoved', () => this.getData())

  }
})
