

import Vue from 'vue';
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { UserTemplate as users } from '../lib/templates/user'
import { DialectTitles, FormatterDialect, Schema, SchemaItem } from '@shared/lib/dialects/models'
import SchemaBuilder from '@shared/components/SchemaBuilder.vue'
import { format } from 'sql-formatter'
import { SqlGenerator } from '@shared/lib/sql/SqlGenerator';
import DialectPicker from '@/components/DialectPicker.vue'
import templates from '@/lib/templates';
import HighlightedCode from '@/components/HighlightedCode.vue';
import { Template } from '@shared/lib/dialects/template';

interface Data {
  template: Template
  schema: Schema
  sql?: string,
  generator: SqlGenerator
  schemaChanges: number
  defaultName: string
}


const dialectNotes = {
  sqlite: "Sqlite does not support table or column comments",
}

export default Vue.extend ({
  metaInfo() {
    return {
      title: "SQL Table Creator",
      meta: [
        { name: 'description', content: this.description}
      ]
    }
  },
  name: 'Home',
  components: {
    SchemaBuilder,
    DialectPicker,
    HighlightedCode
  },
  beforeRouteEnter(to, _from, next) {
    next((component: any) => {
      component.initialize(to.params.id)
      if (!component.template) return "/"
    })
  },
  beforeRouteUpdate(to, _from, next) {
    this.initialize(to.params.id)
    if (!this.template) next('/')
  },
  data(): Data {
    return {
      template: null,
      defaultName: 'untitled_table',
      schema: {
        name: 'untitled_table',
        columns: null
      },
      sql: undefined,
      generator: undefined,
      schemaChanges: 0
    }
  },
  watch: {
    dialect() {
      this.generator.dialect = this.dialect
      this.schemaChanges = 0
      this.schema.columns = this.templateSchema.columns
    },
    schema: {
      deep: true,
      handler() {
        this.generateSql()
      }
    },
  },
  computed: {
    ...mapState(['dialect']),
    ...mapGetters(['knexDialect']),
    description() {
      if (this.isHome) {
        return "Our SQL table creator lets you generate 'CREATE TABLE' statements using a visual interface. Perfect if you can never remember CREATE TABLE syntax. Works for Postgres, MySQL, Sqlite, SQL Server, and Redshift"
      } else {
        return "SQL Table creator for ${this.template.id}. Use our visual SQL table creator for Postgres, MySQL, Sqlite, SQL Server, or Redshift"
      }
    },
    isHome() {
      return this.$route.name === 'BuildRoot'
    },
    confirmMessage() {
      return `You will lose ${this.schemaChanges} changes, continue?`
    },
    templateSchema() {
      return this.template ? this.template.toSchema(this.dialect) : []
    },
    dialectWarning() {
      return dialectNotes[this.dialect]
    },
    dialectTitle() {
      return DialectTitles[this.dialect]
    },
    formattedSql() {
      // TODO (map dialects)
      if (!this.sql) return null
      return format(this.sql, { language: FormatterDialect(this.dialect)})
    }
  },
  methods: {
    generateSql: _.debounce(function() {
      this.sql = this.generator.buildSql(this.schema)
    }, 300),
    initialize(id?: string) {
      this.template = id ? templates.find((t) => t.id === id) : users
      // if (!this.id) this.$router.replace({query: {}})
      if (!this.template) return
      this.schema = _.clone(this.templateSchema)
    },
    columnsChanged(columns: SchemaItem[]) {
      this.schema.columns = columns
      this.schemaChanges += 1
    }
  },
  mounted() {
      this.generator = new SqlGenerator(this.dialect)
  }
})
