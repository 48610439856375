
import { Dialect, DialectTitles } from '@shared/lib/dialects/models'
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  metaInfo() {
    return {
      title: "SQL Code examples by dialect",
      meta: [
        {
          name: 'description',
          content: "Code examples for Postgres, MySQL, Redshift, SQLite, and SQL Server"
        }
      ]
    }
  },
  computed: {
    ...mapState(['dialects']),
  },
  methods: {
    titleFor(dialect: Dialect) {
      return DialectTitles[dialect]
    }
  }
})
